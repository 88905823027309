import  NavbarMenu  from "./component/navbar/Navbar";
import Footer from "./component/footer/Footer";
import About from "./component/about/About";
import Home from "./component/home/Home";
import Product from "./component/product/Product";
import Portfolio from "./component/portfolio/Portfolio";
import Contact from "./component/contact/Contact";
import Staff from "./component/staffing/staff";
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom'
import Terms from "./component/QuickLinks/Terms";
import Disclaimer from "./component/QuickLinks/Disclaimer";
import Sitemap from "./component/QuickLinks/Sitemap";
import PrivacyPolicy from "./component/QuickLinks/PrivacyPolicy"

function App() {
  return (
    <div className="App">
    <Router>
    <NavbarMenu/>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/about' element={<About/>}/>
        <Route exact path='/product' element={<Product/>}/>
        <Route exact path='/contact' element={ <Contact/>}/>
        <Route exact path='/portfolio' element={<Portfolio/>}/>
        <Route exact path="/staff" element={<Staff/>} />
        <Route exact path="/terms" element={< Terms />} />
        <Route exact path="/disclaimer" element={< Disclaimer />} />
        <Route exact path="/privacy" element={< PrivacyPolicy />} />
        <Route exact path="/sitemap" element={< Sitemap />} />
      </Routes>
     <Footer/>
    </Router>
    </div>
  );
}

export default App;
