import {useEffect} from 'react'
import { useLocation } from "react-router-dom";

const Terms = () => {
    const routePath = useLocation();
    const onTop = () => {
      window.scrollTo(0, 0);
    }
    useEffect(() => {
      onTop()
    }, [routePath]);
  return (
    <div className="p-5  mx-lg-5">
      <h1> Terms & Condition</h1>
      As far as the terms and conditions that are stated below are concerned,
      these are supposed to have been accepted by the user once they're making
      use of this website. Please go through them precisely and read them well
      before taking a look at the services that are offered at this gate.
      <br></br>
      <br></br>
      The website of Zentech Info Solutions is offered to you on the base of
      your acceptance of the terms and conditions as well as the announcements
      that have been published herein. On using the website, you're agreeing to
      be bound with the terms and technicalities that relate to the website.
      Zentech Info Solutions may bring about a modification regarding the way
      you're using this website, without inferring you.<br></br>
      <br></br>
      General Terms Since you'll use the website, you won't be permitted to make
      use of the point for illegal purposes or terms that are banned. Also,
      operation will be banned if you using could damage, over burden, vitiate
      or disable any point belonging to Zentech Info Solutions. You shouldn't
      acquire unauthorized access to a point or stoner account, computer network
      or system by virtue of playing word or through any other procedure. We
      don't take responsibility for any kind of damage that's created to the
      computer system of the stoner or in case data is lost by downloading
      content, information or accoutrements from our website.<br></br>
      <br></br>
      Zentech Info Solutions may alter or indeed for that matter discontinue any
      material on the website at any given time and this includes the features
      as well as the content. The right to change or alter the terms and
      conditions of the content rests solely with Zentech Info Solutions.
      <br></br>
      <br></br>
      <b>Content Programs</b> The content that's presented on the website is the
      exclusive property of Zentech Info Solutions. Hence, you can not copy or
      imitate, reproduce or copublish or indeed transmit or upload material
      without gaining the previous concurrence of the company. Unauthorized
      operation of accoutrements that appear in this website will lead to brand,
      legal or trademark violations.<br></br>
      <br></br>
      <b>Sequestration Programs</b> Your sequestration is of utmost significance
      to us and we don't partake information that you have submitted to us
      before any third party association. Take a look at our Sequestration
      Policy regularly to stay streamlined.<br></br>
      <br></br>
      <b>Terms of Use</b> Check out the runner on Terms of Use for detailed
      specifications. Refunds and Cancellation programs are also posted hereon.
      <br></br>
      <br></br>
      <b>Legal Matters</b> This agreement is governed in agreement with the laws
      of the Government of India and by reading it, you have irrevocably acceded
      to the private justice of the law court in case there's any disagreement
      arising from or pertaining to using this website.
    </div>
  );
};

export default Terms;
