import { useEffect, useState } from "react";
import { Container, Image,Modal } from "react-bootstrap";
import Banner from "../../Images/bannerImg.png";
import "./Product.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { API_URL } from "../../Config";

const Product = () => {
  const [product_Data, setProduct_Data] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [banner_Data, setBanner_Data] = useState([]);
  const homeBannerData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/banner/"
      );
      const resData = await response.json();
      setBanner_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const productData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/productandsolution/content/"
      );
      const resData = await response.json();
      setProduct_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
//   console.log(product_Data[0]?.paragraph);

  useEffect(() => {
    productData();
    homeBannerData()
  }, []);
  return (
    <Container fluid>
    {banner_Data.filter((item)=>item.title==="Product and Solution").map((item)=>{
      return(
      <div className="row banner">
        <Image src={item.images[0].image} alt="banner" className="banner-image" />
        <h1 className="banner-title">
        {item.head}
        </h1>
      </div>
      )
    })}
      <Container>
        <div className="products row py-5">
          <h5 className="heading text-center" style={{ color: "#14A7DB" }}>
          OUR PRODUCT
          </h5>
          <h3 className="sub-heading text-center">
            {" "}
            OWN DEVELOPED PRODUCTS
          </h3>
          <p className="text-center offset-1 col-10 ">
            Zentech Info Solutions is a company that works in several spheres of
            technology and platforms such as Mobile, Microsoft, Open Source,
            Java, Frontend, Databases, Cloud & Infrastructure, AI & ML, Data
            Science, etc.
          </p>
                <div className="product-list row my-3 justify-content-around">
          {product_Data.map((data) => {
            return (
                <div className="col-md-6 product_listing">
                  <div
                    className="product_listing_inner p-lg-5 p-md-4 p-3"
                    key={data.id}
                  >
                   {/* {`${item.sub_title.substring(0, 50)}`} */}
                  {ReactHtmlParser(data.paragraph.substring(0, 350)) }

                        <p style={{cursor:"pointer"}} className="py-0 my-0 text-center"><b onClick={() => {
                          setShow(true);
                          setId(data.id);
                        }} >View More</b></p>
                    <h5 className="text-center">{data.title}</h5>

                  </div>
                </div>
            );
          })}
          {product_Data
              .filter((item) => item.id === id)
              .map((item) => {
                return (
                  <>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className=""
                      backdrop="static"
                      size="xl"
                      keyboard={false}
                      // style={{width:"150px"}}
                    >
                      <Modal.Header
                        style={{ marginBottom: "1px solid black" }}
                        closeButton
                      >
                        {/* <Modal.Title>{item.sub_heading}</Modal.Title> */}
                      </Modal.Header>
                      <Modal.Body
                        className=" d-flex justify-content-around"
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <Modal.Title className="ml-5 mt-5 my-5 mx-5">
                          {item.title} 
                        </Modal.Title>
                      </Modal.Body>
                      <Modal.Body className="m-5 pb-5 mb-5">
                        {ReactHtmlParser(item.paragraph)}
                      </Modal.Body>
                    </Modal>
                  </>
                );
              })}
              </div>
        </div>
      </Container>
    </Container>
  );
};

export default Product;
