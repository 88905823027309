import {Button,Container,Nav,Navbar,NavDropdown,Image} from 'react-bootstrap';
import Logo from '../../Images/zentech-logo.svg'
import ISO from '../../Images/ISO.png'
import CMMI from '../../Images/CMMI-Level 5.png'
import Malaysia from '../../Images/Malaysia-call.jpg'
import India from '../../Images/India-call.jpg'
import './Navbar.css'
import { useNavigate } from "react-router-dom";
import {useState} from 'react';


const NavbarMenu = () => {
  let navigate = useNavigate()
  const [isActive, setIsActive] = useState({
    home: true,
    about: false,
    product: false,
    staff: false,
    project: false,
    contact: false,
  });
  const [expanded, setExpanded] = useState(false);
  return (
    <div>
      <div className="header d-flex" >
        <div className="d-none d-lg-block" style={{width: "auto"}}><Image src={CMMI} style={{objectFit:"contain", width: "81%"}}/>
        </div>
        <div className="side-nav" style={{width: "95%"}}>
          <Navbar expand="lg" style={{borderBottom:'1px solid  #F6F6F6',height:'45px'}}>
          <Container fluid className=''>
            <Image className="mob_mt_12 d-flex d-lg-block w-" src={ISO} />
              <div className="contact-info ms-auto d-flex d-none d-lg-block">
                <ul>
                  <li className=''>
                    <Image src={India} /> 
                    <span><a href='telto:02240050010'>022-40050010 / 50</a></span>
                  </li>
                  <li className=''>
                    <Image className='' src={Malaysia} /> 
                    <span><a href='telto:0329359847'>03-2935 9847</a></span>
                  </li>
                </ul>
              </div>
              </Container>
          </Navbar>
          <Navbar  expand="lg" expanded={expanded}>
        <Container fluid>
          <Navbar.Brand href="#"><Image src={Logo} className="logo"  onClick={()=>{navigate('/')}}/></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(!expanded)}/>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0"
              style={{ maxHeight: 'auto' }}
              navbarScroll
            >
              <Nav.Link className='mx-3' href="#action1" style={{ backgroundColor: isActive.home ? '#14A7DB' : 'white', color: isActive.home ? 'white' : 'black' }} onClick={() => { navigate('/'); setExpanded(false); setIsActive({home: true, about: false, product: false, staff: false, project: false, contact: false});}}>Home</Nav.Link>
              <Nav.Link className='mx-3' href="#action1" style={{ backgroundColor: isActive.about ? '#14A7DB' : 'white', color: isActive.about ? 'white' : 'black' }} onClick={() => { navigate('/about'); setExpanded(false); setIsActive({home: false, about: true, product: false, staff: false, project: false, contact: false});}}>About Us</Nav.Link>              
              <Nav.Link className='mx-3' href="#action1" style={{ backgroundColor: isActive.product ? '#14A7DB' : 'white', color: isActive.product ? 'white' : 'black' }} onClick={() => { navigate('/product'); setExpanded(false); setIsActive({home: false, about: false, product: true, staff: false, project: false, contact: false});}}>Product & Services</Nav.Link>            
              <Nav.Link className='mx-3' href="#action1" style={{ backgroundColor: isActive.staff ? '#14A7DB' : 'white', color: isActive.staff ? 'white' : 'black' }} onClick={() => { navigate('/staff'); setExpanded(false); setIsActive({home: false, about: false, product: false, staff: true, project: false, contact: false});}}>Staff Augmentation</Nav.Link>             
              {/* <NavDropdown className='mx-3' title="Resources" id="navbarScrollingDropdown">
                <NavDropdown.Item href="#action3">Staff Augmentation</NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link className='mx-3' href="#action6" style={{ backgroundColor: isActive.project ? '#14A7DB' : 'white', color: isActive.project ? 'white' : 'black' }} onClick={() => { navigate('/portfolio'); setExpanded(false); setIsActive({home: false, about: false, product: false, staff: false, project: true, contact: false});}}>Projects</Nav.Link>                           
              {/* <Nav.Link className='mx-3' href="#action7" >Career</Nav.Link> */}
              <Nav.Link className='mx-3' href="#action7" style={{ backgroundColor: isActive.contact ? '#14A7DB' : 'white', color: isActive.contact ? 'white' : 'black' }} onClick={() => { navigate('/contact'); setExpanded(false); setIsActive({home: false, about: false, product: false, staff: false, project: false, contact: true});}}>Contact us</Nav.Link>                                         
              {/* <Button className='me-5 ms-3 nav-btn' variant="primary" onClick={()=>{navigate('/contact')}}>Contact us</Button> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        </div>
      </div>
    </div>
  )
}

export default NavbarMenu
