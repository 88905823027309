import { useState,useEffect } from "react";
import { Container, Image ,Form} from "react-bootstrap";
import Logo from "../../Images/zentech-logo.svg";
import DunsLogo from "../../Images/DunsLogo.png";
import DunsQr from "../../Images/DunsQr.png";
import Facebook from "../../Images/facebook.png";
import Instagram from "../../Images/instagram.png";
import Linkedin from "../../Images/linkedin.png";
import Twitter from "../../Images/twitter.png";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_URL } from "../../Config";

let files;
const Footer = () => {
  let navigate = useNavigate()
  const [status, setStatus] = useState(false);
  // const [emailInput, setEmailInput] = useState("");
  const [link,setLink] = useState([])
  const [address, setAddress] = useState([])  

  const links=async ()=>{
    try{
      const response = await fetch(API_URL + "/socialmedia/")
      const resData = await response.json()
      setLink(resData)      
    }catch(err){
      console.log(err)
    }
  }

  const getAddress = async () => {
    try {
      const response = await fetch(API_URL + "/address/");
      const resData = await response.json();      
      setAddress(resData);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    links()
    getAddress();
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('files',files);
    if (!files || files.length === 0) {      
      Swal.fire({
        title: 'Please upload your resume before submitting.',
        customClass: {
          confirmButton: 'custom-bg-color', 
        },
      });
      return;
    }
    let formData = new FormData();
    formData.append("resume", files[0]);   
    try {
      let response = await fetch(API_URL + "/upload/", {
        method: "POST",
        body: formData,
      });
      let responseData = await response.json();
      console.log(responseData);
      setStatus(true);
    } catch (err) {
      console.log(err);
    }  
  };
  return (
    <>
      <div
        className="parallax d-flex justify-content-center align-items-center"
        style={{ flexDirection: "column" }}
      >
        <div className="subscribe_newsletter">
          <h5
            className="heading"
            style={{ color: "#14A7DB", textAlign: "center" }}
          >
            CAREER
          </h5>
          <h3 className="mx-5">SUBMIT YOUR RESUME</h3>
          <div className="email-input">
            {status ? (
              <h4 className="mx-5 email-response">
                Thank you for your Response
              </h4>
            ) : (
              <form className="subscribe_form" onSubmit={handleSubmit}>
              <Form.Group controlId="formFileLg" className="">
              <Form.Control
                type="file"
                size="lg"
                onChange={(e) => files = e.target.files}
              />
            </Form.Group>
                {/* <input
                  className="parallax-input"
                  type="file"
                  placeholder="Enter your Resume"
                  onChange={(e) =>files= e.target.files}
                /> */}
                <button className="parallax-btn" type="submit">
                  SUBMIT
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: " #E2F7FF" }}>
        <Container>
          <div className="row pt-5">
            <div className="col-lg-4 col-md-8 ">
              <Image
                src={Logo}
                className="offset-lg-0 col-lg-7 offset-md-4 col-md-4 mb-3"
              />
              {address.map((item) => {
                return (
                  <>
                  <p className="">
                    <h5 className="" style={{ color: " #14A7DB" }}>
                    {item.country.toUpperCase()}
                    </h5>
                  </p>
                  <p style={{marginBottom: "-17px"}}><b>Address:</b>{item.address}</p><br/>
                  <p><b>Phone:</b>{item.mobile_number}</p>
                  </>
                )
              })}
              {/* <p className="text-md-center text-lg-start mt-3 ps-3">
                <h5 className="" style={{ color: " #14A7DB" }}>
                  INDIA:
                </h5>
                <p><b>Address:</b> Zentech Info Solutions PVT. LTD. 810, Centrum IT Park, Plot No C-3, S.G. Barve Road, Opp Railadevi Lake, Wagle Estate, Thane
                400604. <br/> <b>Phone:</b> 022-40050010 / 50</p>
                <h5 className="" style={{ color: " #14A7DB" }}>
                  MALAYSIA:
                </h5>
                <p><b>Address:</b> Zentech Info Solutions SDN. BHD. Unit 3.07, Level 3, KL Gateway Mall, No 2, Jalan Kerinchi, Pantai Dalam, 59200 Kuala Lumpur <br/> <b>Phone:</b> 03-2935 9847</p>
              </p> */}
            </div>
            {/* <div className=" offset-lg-1 col-lg-2  my-5 offset-md-2 col-md-8 text-lg-start text-md-center">
              <h5 className="ms-4" style={{ color: " #14A7DB" }}>
                SERVICES
              </h5>
              <ul className="text-lg-start" style={{ listStyle: "none" }}>
                <li>IT Infrastructure sales & services</li>
                <li>SAP All modules</li>
                <li>Mobility</li>
                <li>Consulting services</li>
                <li>Staff Augmentation</li>
              </ul>
            </div> */}
            <div className="col-lg-4 my-5 mr-5 pt-5 col-md-8 text-lg-start text-md-center">
              <h5 className="ms-4" style={{ color: " #14A7DB" }}>
                QUICK LINKS
              </h5>
              <ul className="text-lg-start" style={{ listStyle: "none" }}>
                <li style={{cursor:"pointer"}} onClick={() => {
                    navigate("/terms");
                  }}>Terms & Condition</li>
                <li style={{cursor:"pointer"}} onClick={() => {
                    navigate("/privacy");
                  }}>Privacy Policy</li>
                <li style={{cursor:"pointer"}} onClick={() => {
                    navigate("/disclaimer");
                  }}>Disclaimer</li>
                {/* <li onClick={() => {
                    navigate("/sitemap");
                  }}>Sitemap</li> */}
              </ul>
              <div className="mt-5">
                <h5 className="ms-4" style={{ color: " #14A7DB" }}>
                  CONTACT INFO
                </h5>
                <div className="text-md-center text-lg-start ms-4 ps-2">
                  <p className="mb-0">For More Info contact :</p>
                  <p>info@zentechinfo.com</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-3">
              <div className="mt-5 pt-5">
                <h5 style={{ color: " #14A7DB" }}>SOCIAL MEDIA LINKS</h5>
                <div className="d-flex justify-content-lg-start justify-content-md-center mt-3">
                  {
                    link.map((data)=>{
                      return(
                        <a href={data.url} target="_blank">
                        <Image className="mx-2" src={data.icon}  />
                        </a>
                      )
                    })
                  }
                </div>
              </div>
              <Image
                className="justify-content-center"
                style={{ marginRight: "15%" }}
                src={DunsQr}
              />
              <Image src={DunsLogo} className="justify-content-center" />
              <p className="mt-2">
                Click here to confirm the online D-U-N-S Registered TM Profile for
                Zentech Info Solutions Pvt Ltd.
              </p>
            </div>
          </div>
        </Container>
      </div>
      <div
        className="row"
        style={{ backgroundColor: "#CCF1FF", color: " #14A7DB" }}
      >
        <p className="text-center mt-3">
          @ Copyright 2023. Zentech Info Solutions Pvt Ltd. All Rights
          Reserved.
        </p>
      </div>
    </>
  );
};

export default Footer;
