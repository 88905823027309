import { useEffect, useState } from 'react'
import { Container, Card, Button, Image, Modal } from "react-bootstrap";
import Pf1 from "../../Images/pf1.png";
import Pf2 from "../../Images/pf2.png";
import Pf3 from "../../Images/pf3.png";
import Pf4 from "../../Images/pf4.png";
import Banner from '../../Images/bannerImg.png';
import "./Portfolio.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { API_URL } from '../../Config';


const Portfolio = () => {
  const [portfolio_Data, setPortfolio_Data] = useState([])
  console.log('portfolio_Data', portfolio_Data);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [banner_Data, setBanner_Data] = useState([]);
  const homeBannerData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/banner/"
      );
      const resData = await response.json();
      setBanner_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const portfolioData = async () => {
    try {
      const response = await fetch(API_URL + "/ourportfolio/content/")
      const resData = await response.json()
      setPortfolio_Data(resData)
      console.log(resData)
    } catch (Err) {
      console.log(Err)
    }
  }
  useEffect(() => {
    portfolioData()
    homeBannerData()
  }, [])
  return (
    <Container fluid>
      {banner_Data.filter((item) => item.title === "Portfolio").map((item) => {
        return (
          <div className="row banner">
            <Image src={item.images[0].image} alt="banner" className="banner-image" />
            <h1 className="banner-title">
              {item.head}
            </h1>
          </div>
        )
      })}
      <Container>
        <div className="portfolio-container row py-5">
          <div className="header">
            <h5 className="heading text-center" style={{ color: "#14A7DB" }}>
              OUR PROJECTS
            </h5>
            <h3 className="sub-heading text-center">OWN CREATED PROJECTS</h3>
            <p className="text-center offset-1 col-10 ">
              Zentech Info Solutions is a company that works in several spheres of
              technology and platforms such as Mobile, Microsoft, Open Source,
              Java, Frontend, Databases, Cloud & Infrastructure, AI & ML, Data
              Science, etc.
            </p>
          </div>
          <div className="portfolio-cards row justify-content-around ">
            {portfolio_Data.map((data) => {              
              return (
                <div className='col-md-6 col-sm-12 my-3'>
                  <Card className="portfolio-card" key={data.id}>
                    <Card.Img variant="top" src={data.image} style={{ height: "300px" }} />
                    <Card.Body className="text-center">
                      <Card.Title>{data.title}</Card.Title>
                      <div style={{ maxHeight: "150px", overflow: "hidden" }}>
                        <Card.Text>
                          {ReactHtmlParser(data.paragraph.substring(0, 350))}
                        </Card.Text>
                      </div>
                      <Button className="btn primary_btn" onClick={(e) => {
                        e.preventDefault();
                        setShow(true);
                        setId(data.id);
                      }}>View More</Button>
                    </Card.Body>
                  </Card>
                </div>
              )
            })}
            {portfolio_Data
              .filter((item) => item.id === id)
              .map((item) => {
                console.log('item',item);
                return (
                  <>
                    <Modal
                      show={show}
                      onHide={handleClose}
                      className=""
                      backdrop="static"
                      size="xl"
                      keyboard={false}
                    // style={{width:"150px"}}
                    >
                      <Modal.Header
                        style={{ marginBottom: "1px solid black" }}
                        closeButton
                      >
                        {/* <Modal.Title>{item.sub_heading}</Modal.Title> */}
                      </Modal.Header>
                      <Modal.Body
                        className="flexColumn d-flex justify-content-around"
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <Modal.Title className="mt-lg-5 mt-md-5 mt-2 my-lg-5 my-md-5 my-3 mx-lg-5 mx-md-5 mx-0">
                          {item.title} <br /> {item.sub_title}
                        </Modal.Title>
                        <Image
                          width="300px"
                          height="250px"
                          style={{ marginBottom: "-15px" }}
                          src={item.image}
                        />
                      </Modal.Body>
                      <Modal.Body className="m-lg-5 m-md-5 m-0 pb-5 mb-5">
                        {ReactHtmlParser(item.paragraph)}
                      </Modal.Body>
                    </Modal>
                  </>
                );
              })}
          </div>
        </div>
      </Container>

    </Container>
  );
};

export default Portfolio;
