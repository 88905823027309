import { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import Banner from "../../Images/bannerImg.png";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import "./staff.css";
import { API_URL } from "../../Config";

const Staff = () => {
    const [resource_Data, setResource_Data] = useState([]);
    const [augumentation_Data, setAugumentation_Data] = useState([]);
    const [talent_Data, setTalent_Data] = useState([]);
    const [banner_Data, setBanner_Data] = useState([]);

    const homeBannerData = async () => {
      try {
        const response = await fetch(          
          API_URL + "/banner/"
        );
        const resData = await response.json();
        setBanner_Data(resData);
      } catch (err) {
        console.log(err);
      }
    };
  const augumentationData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/augmentationservices/"
      );
      const resData = await response.json();
      setAugumentation_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const staffingData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/resourceaugmentation/"
      );
      const resData = await response.json();
      setResource_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const talentData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/augmentationtalent/"
      );
      const resData = await response.json();
      setTalent_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    talentData()
    staffingData();
    augumentationData();
    homeBannerData()
  }, []);
  return (
    <div>
       {banner_Data.filter((item)=>item.title==="STAFF AUGMENTATION").map((item)=>{
      return(
      <div className="row banner">
        <Image src={item.images[0].image} alt="banner" className="banner-image" />
        <h1 className="banner-title">
        {item.head}
        </h1>
      </div>
      )
    })}
      <Container>
        {resource_Data.map((item)=> {
            return(
                <div className="resource  my-5" key={item.id}>
          <h5 className="heading text-center" style={{ color: "#14A7DB" }}>
            {item.head}
          </h5>
          <h3 className="sub-heading text-center  mb-5">
            {item.subhead}
          </h3>
          {/* <p className="text-left resource-content "> */}
           {ReactHtmlParser(item.description)}
          {/* </p> */}
        </div>
            )
        })}
        {/* <div className="resource  my-5">
          <h5 className="heading text-center" style={{ color: "#14A7DB" }}>
            Products & Solutions
          </h5>
          <h3 className="sub-heading text-center  mb-5">
            {" "}
            OWN DEVELOPED PRODUCTS & SOLUTIONS
          </h3>
          <p className="text-left resource-content ">
            In this new era of digital transformation, there has been a rise in
            demand for highly expert and skilled IT professionals in the
            industry. Most IT companies and brands face challenges every day
            with finding and placing the right IT staff or resources.
            <br />
            <br />
            This is where staff augmentation can become a powerful asset.
            <br />
            <br />
            Staff augmentation is an advanced recruitment strategy for companies
            to access larger talents on the market. It is a cost-effective
            solution to expedite your business' growth. Staff augmentation is
            the best way to augment your workforce to get better results faster.
            Through this solution, you can hire skilled people or experts
            without incurring any cost.
            <br />
            <br />
            Staff hiring can be a time-consuming and complex process, as we need
            to hire an experienced HR team to handle the entire hiring process.
            <br />
            <br />
            So, collaborating with a reputed IT staffing company can be a smart
            decision, as it will optimize and accelerate our entire hiring
            process.
            <br />
            <br />
            Zentech Info Solution is an ISO and CMMI-Level 5-recognized IT
            staffing company that provides best-in-class staff augmentation
            services worldwide. We have expertise in recruiting a highly skilled
            IT workforce with our flexible staff augmentation models.
          </p>
        </div> */}
        {/* <div className="staff-services">
          <h3 className="sub-heading text-left my-4" style={{ color: "#14A7DB" ,borderLeft:"8px solid #14A7DB"}}>
           <span className="mx-4 ">Why should you choose our Staff Augmentation services?</span> 
          </h3>
          <h5 className="heading text-left">
            · Unbeatable IT Industry Experience
          </h5>
          <p className="text-left">
            We have 15 years of experience providing IT staff augmentation
            services to top IT brands worldwide.
          </p>
          <h5 className="heading text-left">
          · Trusted by Top IT Companies
          </h5>
          <p className="text-left">
                We have served more than 1500 satisfied clients all over the world and have provided incredible leverage and flexibility for their businesses.
          </p>
          <h5 className="heading text-left">
          · Optimal Quality Standards
          </h5>
          <p className="text-left">
               We are an ISO 9001:2008 and CMMI-level 5 certified staffing company, and that reflects our commitment to quality standards, efficiency, and constant improvement in the IT staffing process.
          </p>
          <h5 className="heading text-left">
          · Talented IT Industry Professionals 
          </h5>
          <p className="text-left">
                We have 300+ engaged IT industry professionals deployed in 50+ countries across the world.
          </p>
          <h5 className="heading text-left">
          · Customized IT Staffing Services
          </h5>
          <p className="text-left">
              We have flexible IT staffing models aligned with the client’s business requirements. We provide staff augmentation services tailored to your company’s needs, whether it's finding talent for a specific position or providing guidance with ex-pat staffing. We'll put together a personalized service agreement to suit your unique requirements and budget.
          </p>
        </div> */}
        {augumentation_Data.map((item)=>{
            return(
                <div className="staff-services text-left" key={item.id}>
          <h3 className="sub-heading text-left my-4" style={{ color: "#14A7DB" ,borderLeft:"8px solid #14A7DB"}}>
           <span className="mx-4 ">{item.head}</span> 
          </h3>
          {ReactHtmlParser(item.description)}
        </div>
            )
        })}
      </Container>
        <div className="staffing-process py-5 my-5" style={{backgroundColor:" #E2F7FF"}}>
      <Container>
        <h5 className="heading text-center" style={{ color: "#14A7DB" }}>
            PROCESS
          </h5>
          <h3 className="sub-heading text-center  mb-5">
          OUR IT STAFFING PROCESS
          </h3>
            <div className="row numberMainBox">
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>01</h1>
                  <p className='mx-4'>Identify client requirements and find a suitable candidate</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>02</h1>
                  <p className='mx-4'>Screening candidate profiles and shortlisting qualified candidates</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>03</h1>
                  <p className='mx-4'>Evaluating candidate performance for appraisals</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>04</h1>
                  <p className='mx-4'>Verification of documents and background</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>05</h1>
                  <p className='mx-4'>Onboarding candidates</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>06</h1>
                  <p className='mx-4'>Managing payrolls</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>07</h1>
                  <p className='mx-4'>Providing applicable perks, benefits, and incentives as per industry standards</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>08</h1>
                  <p className='mx-4'>Evaluating candidate performance for appraisals</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>09</h1>
                  <p className='mx-4'>Leave Management</p>
                </div>
              </div>
              <div className="numberBox">
                <div className="numBoxHgt">
                  <h1 className='mx-4' style={{ color: "#14A7DB" }}>10</h1>
                  <p className='mx-4'>Exit Process</p>
                </div>
              </div>
            </div>
      </Container>
        </div>
          <Container>
          {
            talent_Data.map((item)=>{
                return(
                    <div className="staff-specialisation my-5" key={item.id}>
            <h4>{item.head}</h4>
            <div className="d-flex row">
            <ul className="col-lg-5 mx-5">
                {item.services.filter((item)=>item.id<9).map((item)=>{
                    return(
                        <li className="my-2" key={item.id}>{item.services}</li>
                    )
                })}
                </ul>
            <ul className="offset-lg-1 col-lg-5 mx-5">
            {item.services.filter((item)=>item.id>8).map((item)=>{
                    return(
                        <li className="my-2" key={item.id}>{item.services}</li>
                    )
                })}
            </ul>
            </div>
           {ReactHtmlParser(item.description)}
        </div>   
                )
            })
          }
        {/* <div className="staff-specialisation my-5">
            <h4>Zentech Info Solution has established a proven track record of providing top-quality talents in the following areas:</h4>
            <div className="d-flex row">
            <ul className="col-lg-5 mx-5">
                <li className="my-2">Application Development and Support</li>
                <li className="my-2">Project Management</li>
                <li className="my-2">Infrastructure Management</li>
                <li className="my-2">Quality Assurance</li>
                <li className="my-2">Data Science (AI/ML)</li>
                <li className="my-2">Database Administration</li>
                <li className="my-2">DevOps</li>
                <li className="my-2">Business Intelligence (BI)</li>
                </ul>
            <ul className="offset-lg-1 col-lg-5 mx-5">
                <li className="my-2">Big Data</li>
                <li className="my-2">RPA (Robotic Process Automation)</li>
                <li className="my-2">IoT (Internet of Things)</li>
                <li className="my-2">Web Development (Angular / React)</li>
                <li className="my-2">Mobile App Development (Android/iOS)</li>
                <li className="my-2">Cloud services</li>
                <li className="my-2">IT Support</li>
            </ul>
            </div>
            <p className='mx-4'>If you're looking for a company that can help you scale your business, Zentech Info Solutions is the right choice. Our consultants are experienced, skilled, and have a strong knowledge of advanced technologies.</p>
            <p>We follow a stringent evaluation process to onboard resources and provide extensive training to those resources if required to be client-ready to deliver their projects effectively.</p>
            <p>We are committed to providing you with high-quality services in all areas of expertise that can help you take your business to the next level.</p>
            <p>Our mission is to provide our customers with the highest level of service in all aspects of their staffing relationship with us.</p>
        </div> */}
          </Container>
    </div>
  );
};

export default Staff;
