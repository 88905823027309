import { useState, useEffect } from "react";
import { Container, Card, Image } from "react-bootstrap";
import Banner from "../../Images/bannerImg.png";
import "./Contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation } from '@fortawesome/free-solid-svg-icons'
import { API_URL } from "../../Config";

const Contact = () => {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState();
  const [status, setStatus] = useState(true);
  const [address, setAddress] = useState([]);
  const [banner_Data, setBanner_Data] = useState([]);
  const homeBannerData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/banner/"
      );
      const resData = await response.json();
      setBanner_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(false);
    console.log(firstName, lastName, phone, email, message);
    let formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("mobile_number", phone);
    formData.append("email", email);
    formData.append("description", message);
    try {
      let res = await fetch(API_URL + "/contact/", {
        method: "POST",
        body: formData,
      });
      let resJson = await res.json();
      console.log('resJson',resJson);
      setStatus(false);
    } catch (err) {
      console.log(err);
    }
  };
  const addressDetails = async () => {
    try {
      const response = await fetch(API_URL + "/address/");
      const resData = await response.json();
      setAddress(resData);
      console.log(resData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNumKeyPress = (e) => {
    if (!/^[0-9]*$/.test(e.key)) {
      e.preventDefault();
      return false;
    }
  };

  const validatePhone = (e) => {
    var pattern = /^[7-9][0-9]{9}$/;
    if (document.getElementById('TxtPhoneNo').value != "" || (document.getElementById('TxtPhoneNo').value.length == 10)) {
      if (pattern.test(document.getElementById('TxtPhoneNo').value)) {
        return true
      } else {
        document.getElementById('TxtPhoneNo').focus();
        return false;
      }
    }
  }

  useEffect(() => {
    addressDetails();
    homeBannerData()
  }, []);

  return (
    <Container fluid>
      {banner_Data.filter((item) => item.title === "Contact us").map((item) => {
        return (
          <div className="row banner">
            <Image src={item.images[0].image} alt="banner" className="banner-image" />
            <h1 className="banner-title">
              {item.head}
            </h1>
          </div>
        )
      })}
      <Container>
        <div className="contact row my-5 d-flex justify-content-center">
          <div className=" col-lg-6">
            <Card className="my-3  p-4 contact-card">
              {status ? (
                <form className="row" onSubmit={handleSubmit}>
                  <div className="row">
                    <label className="col-lg-6">
                      <p className="mt-3" style={{ fontSize: "20px" }}>
                        First Name
                      </p>
                      <input pattern="[a-zA-Z]+"
                        title="Accepts only aplhanumeric character with no extra spaces."
                        required
                        className="contact-name"
                        type="text"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </label>
                    <label className=" col-lg-6">
                      <p className="mt-3" style={{ fontSize: "20px" }}>
                        Last Name
                      </p>
                      <input pattern="[a-zA-Z]+"
                        title="Accepts only aplhanumeric character with no extra spaces."
                        required
                        className="contact-name"
                        type="text"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </label>
                  </div>
                  <br />
                  <label>
                    <p className="mt-3" style={{ fontSize: "20px" }}>
                      Enter Phone Number
                    </p>
                    <input
                    maxLength={10}
                    minLength={10}
                      onKeyPress={handleNumKeyPress}
                      className="contact-input "
                      title="Please enter valid mobile."
                      required
                      type="text"
                      onChange={(e) => setPhone(e.target.value)}
                      onBlur={(e) => validatePhone(e)}
                      id="TxtPhoneNo"
                    />
                  </label>
                  <br />
                  <label>
                    <p className="mt-3" style={{ fontSize: "20px" }}>
                      Enter Email Id
                    </p>
                    <input
                      pattern="^[?:[a-zA-Z0-9]+\.]+[A-Za-z]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$"
                      className="contact-input"
                      title="Please enter valid  email address."
                      required
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </label>
                  <br />
                  <label>
                    <p className="mt-3" style={{ fontSize: "20px" }}>
                      Message
                    </p>
                    <textarea
                      className="contact-input"
                      required
                      rows="3"
                      type="text"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </label>
                  <br />
                  <div>
                    <button className="btn d-flex mx-auto my-3" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              ) : (
                <div className="my-5 py-5 mx-auto">
                  <h1>Thank you ! </h1>
                </div>
              )}
            </Card>
          </div>
          <div className="col-lg-6 p-5">
            <div className="contact-add">
              {address.map((data) => {
                return (
                  <div className="add">
                    <div className="d-flex">
                      <Image src={data.icon} />
                      <h5 className="m-3">{data.country}</h5>
                    </div>
                    <div className="d-flex">
                      <i className="icon fa-solid fa-location-dot me-3 mt-2"></i>
                      <p>{data.address}</p>
                    </div>
                    <p><i className="icon fa-solid fa-phone-volume me-3"></i>{data.mobile_number}</p>
                    <p><i className="icon fa-solid fa-envelope me-3"></i>{data.email}</p>

                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default Contact;
