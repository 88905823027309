import { useState, useEffect } from "react";
import { Button, Container, Image, Carousel ,Modal} from "react-bootstrap";
import "./Home.css";
import Hpi from "../../Images/hpi.png";
import { useNavigate } from "react-router-dom";
import CarouselI from "../../Images/carousel.png";
import AboutI from "../../Images/about.png";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { API_URL } from "../../Config";


const Home = () => {
  const [product_Data, setProduct_Data] = useState([]);
  const [caorusel_Data, seCarousel_Data] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [show,setShow]= useState(false)
  const [id,setId]= useState()
  const handleClose = () => setShow(false);
  let navigate = useNavigate();
  const aboutHomeData = async () => {
    try {
      const response = await fetch(API_URL + "/home_about_us/");      
      const resData = await response.json();
      console.log('resData',resData);
      setAboutData(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const productData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/content/"
      );
      console.log('response',response);
      const resData = await response.json();
      setProduct_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const ProjectsHomeData = async () => {
    try {
      const response = await fetch(API_URL + "/projects/");
      const resData = await response.json();
      setProjectData(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const homeCaoruselData = async () => {
    try {
      const response = await fetch(API_URL + "/banner/");
      const resData = await response.json();
      seCarousel_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    homeCaoruselData();
    aboutHomeData();
    ProjectsHomeData();
    productData();
  }, []);
  return (
    <>
      <div style={{ backgroundColor: "#F9F9F9" }} className="banner_bg">
        <Carousel>
          {caorusel_Data
            .filter((item) => item.title === "home banner")
            .map((item) => {
              return (
                <Carousel.Item>
                  <img
                    className="Carousel_img d-block w-100"
                    src={item.images[0].image}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h1>{item.head}</h1>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
      <Container fluid>
        {aboutData.map((item) => {
          return (
            <div className="row haboutus">
              <div className="col-lg-6 col-sm-12 haboutus_left">
                <Image
                  className="offset-2 col-8  my-5"
                  src={item.images[0].image}
                />
              </div>
              <div className="col-lg-6 col-sm-12 p-lg-5 p-md-4 p-3 haboutus_right">
                <h5 className="heading">{item.head}</h5>
                <h3 className="sub-heading">{item.sub_head}</h3>
                <p className="col-lg-10 col-md-10 col-12">{item.description}</p>
                <Button
                  className="secondary_btn"
                  onClick={() => {
                    navigate("/about");
                  }}
                  style={{ border: "1px solid #fff" }}
                >
                  {" "}
                  View More
                </Button>
              </div>
            </div>
          );
        })}
      </Container>
      <div className="home_products_solutions py-lg-5 py-md-4">
        <Container>
          <div className="header text-center pt-5">
            <h5 className="heading" style={{ color: "#14A7DB" }}>
              PRODUCT & SOLUTIONS
            </h5>
            <h3 className="sub-heading">OWN DEVELOPED PROJECTS</h3>
          </div>
          <div className="row home_product_box_sec justify-content-around">
            {product_Data.filter((item,i)=>i<10).map((item)=>{
              return(
            <div className="col-lg-2 my-3 home_product_box card-ps" 
                    onClick={() => {
                          setShow(true);
                          setId(item.id);
                        }}>
              <p>{item.title}</p>
            </div>
              )
            })}
          </div>
            {
              product_Data.filter((item) => item.id === id).map((item)=>{
                return(
                  <Modal
                      show={show}
                      onHide={handleClose}
                      className=""
                      backdrop="static"
                      size="xl"
                      keyboard={false}
                      // style={{width:"150px"}}
                    >
                      <Modal.Header
                        style={{ marginBottom: "1px solid black" }}
                        closeButton
                      >
                        {/* <Modal.Title>{item.sub_heading}</Modal.Title> */}
                      </Modal.Header>
                      <Modal.Body
                        className=" d-flex justify-content-around"
                        style={{ borderBottom: "1px solid gray" }}
                      >
                        <Modal.Title className="ml-5 mt-5 my-5 mx-5">
                          {item.title} 
                        </Modal.Title>
                      </Modal.Body>
                      <Modal.Body className="m-5 pb-5 mb-5">
                      {ReactHtmlParser(item.paragraph) }
                      </Modal.Body>
                    </Modal>
                )
              })
            }
          <div
            className="row justify-content-around"
            style={{ backgroundColor: "#F9F9F9" }}
          ></div>
        </Container>
      </div>
      <Container>
        <div className="header text-center my-5 ">
          <h5 style={{ color: "#14A7DB" }}>Our Projects</h5>
          <h3>OWN DEVELOPED PROJECTS</h3>
        </div>
            <div className="portfolio-container d-flex justify-content-around">
        {projectData.map((item) => {
          return (
              <div className="portfolio-home m-2 ">
                <Image
                  src={item.image}
                  className="project_box"
                />
              </div>
          );
        })}
            </div>
      </Container>
      <Button className="btn d-flex mx-auto my-5" onClick={() => {
                    navigate("/portfolio");
                  }}>VIEW ALL</Button>
    </>
  );
};

export default Home;
