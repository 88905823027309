import { useEffect,useState } from "react";
import { Button, Container, Image,Carousel } from "react-bootstrap";
import Test from "../../Images/bgImg.png";
import Team from "../../Images/team.png";
import Banner from "../../Images/bannerImg.png";
import "./About.css";
import { API_URL } from "../../Config";

const About = () => {
  const [about_Data,setAbout_Data] = useState([]);
  const [banner_Data, setBanner_Data] = useState([]);
  const [testimonial_Data, setTestimonial_Data] = useState([]);
  const [leader_Data, setLeader_Data] = useState([]);

  const teamData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/team/"
      );          
      const resData = await response.json();      
      setLeader_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const testimonialData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/testimonial/"
      );
      const resData = await response.json();
      setTestimonial_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };  
  const homeBannerData = async () => {
    try {
      const response = await fetch(        
        API_URL + "/banner/"
      );
      const resData = await response.json();
      setBanner_Data(resData);
    } catch (err) {
      console.log(err);
    }
  };
  const aboutData = async () => {
    try {
      const response = await fetch(API_URL + "/about/");
      const resData = await response.json();
      setAbout_Data(resData)
    } catch (err) {
      console.log(err);
    }
  };  
  useEffect(() => {
    aboutData();
    homeBannerData()
    testimonialData()
    teamData()
  }, []);
  return (
    <div>
        {banner_Data.filter((item)=>item.title==="About Us").map((item)=>{
      return(
      <div className="row banner">
        <Image src={item.images[0].image} alt="banner" className="banner-image" />
        <h1 className="banner-title">
        {item.head}
        </h1>
      </div>
      )
    })}
        <Container>
        <div className='row py-5'>
        {about_Data.map((data)=>{
          return(
          <div className=' col-12 my-3' key={data.id}>
            <div className='about-header mb-3  p-3'style={{backgroundColor:'#F6F6F6'}}>
              <h3>{data.title}</h3>
              <p className='ps-3' style={{borderLeft:'7px solid #14A7DB'}}>{data.paragraph}</p>
            </div>
            <div className='about-content'>
              {data.description.map((sub_data)=>{
                return(
              <p className="mx-2">{sub_data.description}</p>
                  
                )
              })}
            </div>
          </div>
          )
        })}
          {/* <div className=' col-12 mb-3 '>
            <div className='about-header mb-3  p-3'style={{backgroundColor:'#F6F6F6'}}>
              <h3>300 + Employees</h3>
              <p className='ps-3' style={{borderLeft:'7px solid #14A7DB'}}>Founded in 2007, the company is headquartered in Mumbai over 300 people. We are a Team of Engineers, Managers and Administrators working together towards a single goal. We believe in providing World Class service to our customers through talented and motivated team.</p>
            </div>
            <div className='about-content'>
              <p>A top-notch team who are highly skilled engineers having a great experience in solving complex problems.<br/>At Zentech Info Solutions, we have a team of engineers that are world-class. We have specialists in every field imaginable, including ML & AI specialists.</p>
            </div>
          </div>
          <div className=' col-12 mb-3 '>
            <div className='about-header mb-3  p-3'style={{backgroundColor:'#F6F6F6'}}>
              <h3>200 + Happy Clients (Team that you can trust)</h3>
              <p className='ps-3' style={{borderLeft:'7px solid #14A7DB'}}>Zentech Info Solutions is a veteran in the IT space, with over 20 years of experience providing IT services to government and private sectors. Our highly-experienced team has successfully implemented various projects for various business verticals, governments, international organizations and enterprises across the world.</p>
            </div>
            <div className='about-content'>
              <p>Our clients include Fortune 500 companies like NTPC, Schneider Electric, Binani, Aditya Birla etc.<br/>Zentech Info Solutions has been relentlessly growing for the last 13 years, now with over 200 clients in more than 20 countries.<br/> Industry leading solutions: We offer an extensive list of services, ranging from website design or development to maintenance and management services. Our team has deep expertise in all aspects of Information Technology (IT) services.Zentech Info Solutions is a team of professionals equipped to solve all your business and technology challenges. Over the years, we have delivered unmatched solutions to 200+ happy clients with client appreciation.</p>
            </div>
          </div>
          <div className=' col-12 mb-3 '>
            <div className='about-header mb-3  p-3'style={{backgroundColor:'#F6F6F6'}}>
              <h3>500 + Project Completed</h3>
              <p className='ps-3' style={{borderLeft:'7px solid #14A7DB'}}>Zentech Info Solutions is a leading enterprise digital consulting and technology services company. We are trusted partners to our clients, helping them navigate complex digital transformations with confidence and ease.</p>
            </div>
            <div className='about-content'>
              <p>Zentech Info Solutions has been known for their quality and ability to deliver on time. We are your technology partner for all of your Digital Transformation needs.<br/>Zentech Info Solutions brings the most advanced technology to your project and offers the most experienced team to handle your project. We have successfully completed over 500 projects with our clients across the globe.</p>
            </div>
          </div> */}
        </div>
        </Container>
        <Container fluid>
          <div className='row team py-5' style={{backgroundColor:'#E2F7FF'}}>
                <h5 className='text-center pt-4' style={{color:'#14A7DB'}}>OUR TEAM</h5>
                <h3 className='text-center'>MEET OUR TEAM MEMBERS</h3>
                <Container fluid>
                <div className='my-3 row d-flex justify-content-center '>
                  {leader_Data.map((item)=>{
                    return(
                            <div className='team-member col-lg-2 col-md-8 mb-5'>
                                <Image src={item.image} style={{objectFit:"cover"}} className='col-lg-12 col-md-12 ' />
                                <h5 className='text-center'>{item.name}</h5>
                                <p className='text-center'>{item.designation}</p>
                            </div>
                      )
                    })}
                </div>
                </Container>
          </div>
        </Container>
        <div style={{ backgroundColor: "#14A7DB",color:"#fff" }} className="testimonial">
          <h5 className="text-center pt-5">TESTIMONIAL</h5>
          <h1 className="text-center">OUR CLIENT REVIEW</h1>
          <Carousel>
          {testimonial_Data.map((item)=>{
            return(
              <Carousel.Item key={item.id} >
                <img
                  height='300px'
                  className="Carousel_img d-block w-100"
                  src={Test}
                  alt="First slide"
                />
                <Carousel.Caption >
                  <p  className="my-5 review" >{item.review}</p>
                  {/* <h2 style={{ fontSize: '18px', fontWeight: '700', textTransform: 'uppercase', letterSpacing: '1px'}}>{item.designation}</h2> */}
                  {/* <h5 style={{ fontSize: '16px', fontWeight: '400' }}>{item.name}</h5> */}
                  <Image src={item.client_logo}/>
                </Carousel.Caption>
              </Carousel.Item>
            )
            })}
          </Carousel>
      </div>
    </div>
  );
};

export default About;
